<template lang="pug">
.notification-bar.sticky-top(
  v-if="isNotSubUser && !hideStickyBar"
  :class="{ 'd-none': $route.name === 'variant_edit' }"
)
  component(:is="notificationComponent")
</template>

<script>
  import { mapState, createNamespacedHelpers, mapGetters } from 'vuex';
  import upgradePlanModal from '@/mixins/upgradePlanModal';
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';
  import PaymentNotificationService from '@/services/PaymentNotificationService';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    name: 'NotificationBar',
    components: {
      UilExclamationTriangle,
      PlanExpires: () => import('@/components/Notifications/Payment/PlanExpires.vue'),
      NotificationForInvitedUsers: () =>
        import('@/components/Notifications/Payment/NotificationForInvitedUsers'),
      PaymentProblem: () => import('@/components/Notifications/Payment/PaymentProblem.vue'),
      AccountExpired: () => import('@/components/Notifications/Payment/AccountExpired.vue'),
      LimitProblem: () => import('@/components/Notifications/Payment/LimitProblem.vue'),
      PriceChange: () => import('@/components/Notifications/Payment/PriceChange.vue'),
      GracePeriodForPriceChange: () =>
        import('@/components/Notifications/Payment/GracePeriodForPriceChange.vue'),
    },
    mixins: [upgradePlanModal],

    computed: {
      ...mapState(['account', 'hideStickyBar']),
      ...mapGetters(['isPaying']),
      ...paymentGetters([
        'hasBankTransferPaymentProblem',
        'hasBraintreePaymentProblem',
        'hasShopifyPaymentProblem',
        'isBraintreePayment',
        'isPlanExpired',
        'needsShopifyPlanUpdateWithNewPrice',
        'hasGracePeriodForPriceChange',
        'getLimitProblemType',
        'isPaymentEnabledForUser',
      ]),
      isNotSubUser() {
        return this.account && this.account.type !== 'sub';
      },
      notificationComponent() {
        const service = new PaymentNotificationService(
          this.account,
          this.isPaymentEnabledForUser,
          this.isPaying,
          this.hasBankTransferPaymentProblem,
          this.hasBraintreePaymentProblem,
          this.hasShopifyPaymentProblem,
          this.isBraintreePayment,
          this.isPlanExpired,
          this.needsShopifyPlanUpdateWithNewPrice,
          this.hasGracePeriodForPriceChange,
          this.getLimitProblemType,
        );
        return service.determineNotificationComponent();
      },
    },
  };
</script>
