import { PAYMENT_NOTIFICATION_COMPONENT_NAMES } from '@/components/Notifications/Payment';
import { LIMIT_PROBLEM_TYPES } from '@/config/limitProblemTypes';

export default class PaymentNotificationService {
  constructor(
    account,
    isOwner,
    isPaying,
    hasBankTransferPaymentProblem,
    hasBraintreePaymentProblem,
    hasShopifyPaymentProblem,
    isBraintreePayment,
    isPlanExpired,
    needsShopifyPlanUpdateWithNewPrice,
    hasGracePeriodForPriceChange,
    getLimitProblemType,
  ) {
    this.account = account;
    this.isOwner = isOwner;
    this.isPaying = isPaying;
    this.hasBankTransferPaymentProblem = hasBankTransferPaymentProblem;
    this.hasBraintreePaymentProblem = hasBraintreePaymentProblem;
    this.hasShopifyPaymentProblem = hasShopifyPaymentProblem;
    this.isBraintreePayment = isBraintreePayment;
    this.isPlanExpired = isPlanExpired;
    this.needsShopifyPlanUpdateWithNewPrice = needsShopifyPlanUpdateWithNewPrice;
    this.hasGracePeriodForPriceChange = hasGracePeriodForPriceChange;
    this.getLimitProblemType = getLimitProblemType;
  }

  hasPaymentProblem() {
    return (
      this.hasBankTransferPaymentProblem ||
      this.hasBraintreePaymentProblem ||
      this.hasShopifyPaymentProblem
    );
  }

  isExpired() {
    const expires = new Date(this.account.billing.dateExpires).getTime();
    const today = new Date().setHours(0, 0, 0, 0);
    return expires + 86400 < today;
  }

  isPlanExpiredBraintree() {
    return this.isBraintreePayment && this.isPaying && this.isPlanExpired;
  }

  isInvitedUser() {
    return !this.isOwner;
  }

  hasAnyPaymentProblem() {
    return this.hasPaymentProblem() || this.isPlanExpiredBraintree() || this.isExpired();
  }

  hasAnyLimitProblem() {
    return this.getLimitProblemType !== null;
  }

  shouldShowNotificationForInvitedUser() {
    return (
      this.isInvitedUser() &&
      (this.hasAnyPaymentProblem() ||
        (this.getLimitProblemType &&
          this.getLimitProblemType !== LIMIT_PROBLEM_TYPES.NON_PAYING_OVER_50P))
    );
  }

  determineNotificationComponent() {
    const {
      ACCOUNT_EXPIRED,
      GRACE_PERIOD_FOR_PRICE_CHANGE,
      LIMIT_PROBLEM,
      NOTIFICATION_FOR_INVITED_USERS,
      PAYMENT_PROBLEM,
      PRICE_CHANGE,
      PLAN_EXPIRES,
    } = PAYMENT_NOTIFICATION_COMPONENT_NAMES;

    if (this.shouldShowNotificationForInvitedUser()) return NOTIFICATION_FOR_INVITED_USERS;
    if (this.isExpired()) return ACCOUNT_EXPIRED;
    if (this.hasPaymentProblem()) return PAYMENT_PROBLEM;
    if (this.isPlanExpiredBraintree() || this.isExpired()) return PLAN_EXPIRES;
    if (this.hasAnyLimitProblem() && !this.isInvitedUser()) return LIMIT_PROBLEM;
    if (this.hasGracePeriodForPriceChange) return GRACE_PERIOD_FOR_PRICE_CHANGE;
    if (this.needsShopifyPlanUpdateWithNewPrice) return PRICE_CHANGE;

    return null;
  }
}
